import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
// import logoLight from '../../public/logo/logo_on_light.svg';
// import logoDark from 'logo/logo_on_dark.svg';
import Image from './Image';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // const logo = '/logo/logo_single.svg';

  const logo = (
    <Box sx={{ 
      // marginTop:5, 
      width: {xs:180,md:200}, 
      height: {xs:60,md:80},
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      ...sx }}>
      <Image src={'/logo/logo_on_dark.svg'} 
      sx={{
        width: 'auto', 
      height: 'auto',
        }} />
      {/* <img src={require('/logo')} /> */}
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
