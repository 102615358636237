import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import Countup from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import { useMotionValue, useTransform, animate, m, motion } from 'framer-motion';
import { varFade } from './variants';

export default function CountNumber({ from = 0, to, prefix = '', suffix = '', variants, sx, ...other }) {
  const count = useMotionValue(from);
  const rounded = useTransform(count, Math.round);
  const [isShow, setShow] = useState(false);

  useEffect(() => {
    const animation = animate(count, to, {
      duration: 2,
    });

    rounded.on(() => {
      console.log(rounded);
    });

    return () => animation.stop;
  }, []);

  console.log('Count:', count.get());

  return (
    <ScrollTrigger onEnter={() => setShow(true)} onExit={() => setShow(false)}>
      <Box
        component={m.h1}
        sx={{
          // typography: 'h1',
          overflow: 'hidden',
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        {isShow ? (
          <Countup start={from} end={to} duration={2} delay={0} prefix={prefix} suffix={suffix} />
        ) : (
          <m.span variants={variants || varFade().inUp}>{`${prefix}${to}${suffix}`}</m.span>
        )}
      </Box>
    </ScrollTrigger>
  );
}
