import PropTypes from 'prop-types';
import { useEffect, createContext, useState } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
// hooks
import useLocalStorage from '../hooks/useLocalStorage';
// utils
import getColorPresets, { colorPresets, defaultPreset } from '../utils/getColorPresets';
// config
import { GA_MEASUREMENT_ID, defaultSettings } from '../config';
import axiosInstance from '../utils/axios';

ReactGA.initialize(GA_MEASUREMENT_ID);

// ----------------------------------------------------------------------

const AppContext = createContext();

// ----------------------------------------------------------------------

AppProvider.propTypes = {
  children: PropTypes.node,
};

function AppProvider({ children }) {
  //   const location = useLocation();
  const [savings, setSavings] = useState(null);
  const [clientSavings, setClientSavings] = useState(null);

  //   useEffect(() => {
  //     ReactGA.pageview(location.pathname + location.search);
  //   }, [location]);

  useEffect(() => {
    getSavingsDetail();
    getClientSavingsDetail();
  }, []);

  function getSavingsDetail() {
    axiosInstance
      .get('https://leads.qilinlab.com/aws-saving/savings-till-date/')
      .then((response) => {
        setSavings(response.data);
      })
      .catch((err) => {
        console.log('Error:', err);
      });
  }
  function getClientSavingsDetail() {
    axiosInstance
      .get('https://leads.qilinlab.com/aws-saving/client-saving-detail/')
      .then((response) => {
        setClientSavings(response.data);
      })
      .catch((err) => {
        console.log('Error:', err);
      });
  }

  // Mode

  return <AppContext.Provider value={{ savings, clientSavings }}>{children}</AppContext.Provider>;
}

export { AppProvider, AppContext };
