import { Link as RouterLink } from 'react-router-dom';
// @mui
import { format } from 'date-fns';

import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack, useTheme } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import SocialsButton from '../../components/SocialsButton';
import Image from '../../components/Image';

// ----------------------------------------------------------------------

const LINKS = [
  // {
  //   headline: 'Minimal',
  //   children: [
  //     { name: 'About us', href: PATH_PAGE.about },
  //     { name: 'Contact us', href: PATH_PAGE.contact },
  //     { name: 'FAQs', href: PATH_PAGE.faqs },
  //   ],
  // },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: '#' },
      { name: 'Privacy Policy', href: '#' },
    ],
  },
  {
    headline: 'Contact',
    children: [
      { name: 'support@qilinlab.com', href: '#' },
      { name: '211/5, Gali No.5, Padmanagar Kishanganj, Delhi - 110007', href: '#' },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const theme = useTheme();
  return (
    <RootStyle>
      <Divider />
      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={4}
              sx={{
                [theme.breakpoints.down('md')]: {
                  justifyContent: 'center',
                },
              }}
            >
              <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
              <Image sx={{ height: 50 }} src="/assets/icons/aws_partner.png" />
            </Stack>
          </Grid>
          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              Delivering real world changes through our close-knit , hyper productive team
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton
                sx={{ mx: 0.5 }}
                links={{
                  linkedin: 'https://www.linkedin.com/company/qilinlab',
                  facebook: 'https://www.facebook.com/qilinlab/',
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline">
                    {list.headline}
                  </Typography>
                  {list.children.map((link) => (
                    <Link
                      to={link.href}
                      key={link.name}
                      color="inherit"
                      variant="body2"
                      component={RouterLink}
                      sx={{ display: 'block' }}
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          {`© ${format(new Date(), 'yyyy')}. All rights reserved.`}
        </Typography>
      </Container>
    </RootStyle>
  );
}
